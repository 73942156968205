import React, { useState, useEffect, useRef, useCallback } from 'react';
import DatasetsGrey from '../../images/datasets_grey.png';
import Metrics from '../../images/metrics-slate.svg';
import { Transition } from '../utils/Transitions';

const searchItems = [
  // Action items
  { name: 'View latest traces', path: '/datastore/sessions', type: 'action', icon: (
    <svg className="shrink-0 h-4 w-4" viewBox="0 0 24 24">
      <path className="fill-current text-slate-600" d="M13 15l11-7L11.504.136a1 1 0 00-1.019.007L0 7l13 8z" />
      <path className="fill-current text-slate-800" d="M13 15L0 7v9c0 .355.189.685.496.864L13 24v-9z" />
      <path className="fill-current text-slate-900" d="M13 15.047V24l10.573-7.181A.999.999 0 0024 16V8l-11 7.047z" />
    </svg>
  )},
  { name: 'Create a chart', path: '/discover', type: 'action', icon: (
    <svg className="shrink-0 h-4 w-4" viewBox="0 0 24 24">
      <path className="fill-current text-slate-800" d="M4.418 19.612A9.092 9.092 0 0 1 2.59 17.03L.475 19.14c-.848.85-.536 2.395.743 3.673a4.413 4.413 0 0 0 1.677 1.082c.253.086.519.131.787.135.45.011.886-.16 1.208-.474L7 21.44a8.962 8.962 0 0 1-2.582-1.828Z" />
      <path className="fill-current text-slate-700" d="M10.034 13.997a11.011 11.011 0 0 1-2.551-3.862L4.595 13.02a2.513 2.513 0 0 0-.4 2.645 6.668 6.668 0 0 0 1.64 2.532 5.525 5.525 0 0 0 3.643 1.824 2.1 2.1 0 0 0 1.534-.587l2.883-2.882a11.156 11.156 0 0 1-3.861-2.556Z" />
      <path className="fill-current text-slate-700" d="M21.554 2.471A8.958 8.958 0 0 0 18.167.276a3.105 3.105 0 0 0-3.295.467L9.715 5.888c-1.41 1.408-.665 4.275 1.733 6.668a8.958 8.958 0 0 0 3.387 2.196c.459.157.94.24 1.425.246a2.559 2.559 0 0 0 1.87-.715l5.156-5.146c1.415-1.406.666-4.273-1.732-6.666Zm.318 5.257c-.148.147-.594.2-1.256-.018A7.037 7.037 0 0 1 18.016 6c-1.73-1.728-2.104-3.475-1.73-3.845a.671.671 0 0 1 .465-.129c.27.008.536.057.79.146a7.07 7.07 0 0 1 2.6 1.711c1.73 1.73 2.105 3.472 1.73 3.846Z" />
    </svg>
  )},
  { name: 'Create an evaluator', path: '/metrics#create-evaluator', type: 'action', icon: (
    <img className="shrink-0 h-4 w-4" src={Metrics.src} alt="Evaluator" />
  )},
  { name: 'Create a prompt', path: '/studio/playground', type: 'action', icon: (
    <svg className="shrink-0 h-4 w-4" viewBox="0 0 895 895">
      <path
        className="fill-current text-slate-600"
        d="M831 127H63c-35.35 0-64 28.65-64 64v640c0 35.35 28.65 64 64 64h768c35.35 0 64-28.65 64-64V191c0-35.35-28.65-64-64-64zM127 575l128-128L127 319l64-64 192 192L191 639l-64-64zM639 639H383v-64h256v64z"
      />
    </svg>
  )},
  { name: 'Invite a teammate', path: '/settings/account', type: 'action', icon: (
    <svg className="shrink-0 h-4 w-4" viewBox="0 0 24 24">
      <path className="fill-current text-slate-600" d="M19.714 14.7l-7.007 7.007-1.414-1.414 7.007-7.007c-.195-.4-.298-.84-.3-1.286a3 3 0 113 3 2.969 2.969 0 01-1.286-.3z" />
      <path className="fill-current text-slate-400" d="M10.714 18.3c.4-.195.84-.298 1.286-.3a3 3 0 11-3 3c.002-.446.105-.885.3-1.286l-6.007-6.007 1.414-1.414 6.007 6.007z" />
      <path className="fill-current text-slate-600" d="M5.7 10.714c.195.4.298.84.3 1.286a3 3 0 11-3-3c.446.002.885.105 1.286.3l7.007-7.007 1.414 1.414L5.7 10.714z" />
      <path className="fill-current text-slate-400" d="M19.707 9.292a3.012 3.012 0 00-1.415 1.415L13.286 5.7c-.4.195-.84.298-1.286.3a3 3 0 113-3 2.969 2.969 0 01-.3 1.286l5.007 5.006z" />
    </svg>
  )},
  
  // Page items
  { name: 'Go to Data Store', path: '/datastore/sessions', type: 'page', icon: (
    <svg className="shrink-0 h-4 w-4" viewBox="0 0 24 24">
      <path className="fill-current text-slate-600" d="M13 15l11-7L11.504.136a1 1 0 00-1.019.007L0 7l13 8z" />
      <path className="fill-current text-slate-800" d="M13 15L0 7v9c0 .355.189.685.496.864L13 24v-9z" />
      <path className="fill-current text-slate-900" d="M13 15.047V24l10.573-7.181A.999.999 0 0024 16V8l-11 7.047z" />
    </svg>
  )},
  { name: 'Go to Evaluations', path: '/evaluate', type: 'page', icon: (
    <svg className="shrink-0 h-4 w-4" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg"> 
      <rect x="2" y="10" width="5" height="11" rx="1"/>
      <rect x="9.5" y="3" width="5" height="18" rx="1"/>
      <rect x="17" y="6" width="5" height="15" rx="1"/>
    </svg>
  )},
  { name: 'Go to Dashboard', path: '/dashboard', type: 'page', icon: (
    <svg className="shrink-0 h-4 w-4" viewBox="0 0 200 200" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <rect x="10" y="10" width="80" height="80" rx="20" />
      <rect x="110" y="10" width="80" height="80" rx="20" />
      <rect x="10" y="110" width="80" height="80" rx="20" />
      <rect x="110" y="110" width="80" height="80" rx="20" />
    </svg>
  )},
  { name: 'Go to Evaluators', path: '/metrics', type: 'page', icon: (
    <img className="shrink-0 h-4 w-4" src={Metrics.src} alt="Evaluators" />
  )},
  { name: 'Go to Datasets', path: '/datasets', type: 'page', icon: (
    <img className="shrink-0 w-4" src={DatasetsGrey.src} alt="Datasets" />
  )},
  { name: 'Go to Prompt Registry', path: '/studio/library', type: 'page', icon: (
    <svg className="shrink-0 h-4 w-4" viewBox="0 0 895 895">
      <path
        className="fill-current text-slate-600"
        d="M831 127H63c-35.35 0-64 28.65-64 64v640c0 35.35 28.65 64 64 64h768c35.35 0 64-28.65 64-64V191c0-35.35-28.65-64-64-64zM127 575l128-128L127 319l64-64 192 192L191 639l-64-64zM639 639H383v-64h256v64z"
      />
    </svg>
  )},
  { name: 'Go to Tools', path: '/studio/tools', type: 'page', icon: (
    <svg className="shrink-0 h-4 w-4" viewBox="0 0 895 895">
      <path
        className="fill-current text-slate-600"
        d="M831 127H63c-35.35 0-64 28.65-64 64v640c0 35.35 28.65 64 64 64h768c35.35 0 64-28.65 64-64V191c0-35.35-28.65-64-64-64zM127 575l128-128L127 319l64-64 192 192L191 639l-64-64zM639 639H383v-64h256v64z"
      />
    </svg>
  )},
  { name: 'Go to Settings', path: '/settings/apps', type: 'page', icon: (
    <svg className="shrink-0 h-4 w-4" viewBox="0 0 24 24">
      <path className="fill-current text-slate-600" d="M19.714 14.7l-7.007 7.007-1.414-1.414 7.007-7.007c-.195-.4-.298-.84-.3-1.286a3 3 0 113 3 2.969 2.969 0 01-1.286-.3z" />
      <path className="fill-current text-slate-400" d="M10.714 18.3c.4-.195.84-.298 1.286-.3a3 3 0 11-3 3c.002-.446.105-.885.3-1.286l-6.007-6.007 1.414-1.414 6.007 6.007z" />
      <path className="fill-current text-slate-600" d="M5.7 10.714c.195.4.298.84.3 1.286a3 3 0 11-3-3c.446.002.885.105 1.286.3l7.007-7.007 1.414 1.414L5.7 10.714z" />
      <path className="fill-current text-slate-400" d="M19.707 9.292a3.012 3.012 0 00-1.415 1.415L13.286 5.7c-.4.195-.84.298-1.286.3a3 3 0 113-3 2.969 2.969 0 01-.3 1.286l5.007 5.006z" />
    </svg>
  )}
];

// Add shortcuts to searchItems
const addShortcuts = (items) => {
  const shortcuts = [
    '1', '2', '3', '4', '5', '6', '7', '8', '9', '0',
    '!', '@', '#', '$', '%', '^', '&', '*', '(', ')'
  ];
  
  return items.map((item, index) => ({
    ...item,
    shortcut: shortcuts[index] || ''
  }));
};

const searchItemsWithShortcuts = addShortcuts(searchItems);

const SpotlightSearch = ({ isOpen, setIsOpen, router }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const searchRef = useRef(null);
  const listRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setIsOpen(true);
      } else if (e.key === 'Escape') {
        setIsOpen(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [setIsOpen]);

  useEffect(() => {
    if (isOpen) {
      searchRef.current.focus();
      setSelectedIndex(-1);
    }
  }, [isOpen]);

  useEffect(() => {
    const filtered = searchItemsWithShortcuts.filter(item =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredItems(filtered);
    setSelectedIndex(-1);
  }, [searchTerm]);

  const handleItemClick = useCallback((path) => {
    setIsOpen(false);
    setSearchTerm('');
    // Force a hard navigation
    window.location.href = path;
  }, [setIsOpen]);

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setSelectedIndex(prevIndex => 
        prevIndex < filteredItems.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setSelectedIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    } else if (e.key === 'Enter') {
      e.preventDefault();
      if (filteredItems.length === 1) {
        // If there's only one item, select it
        handleItemClick(filteredItems[0].path);
      } else if (selectedIndex >= 0) {
        // If there are multiple items and one is selected, choose the selected one
        handleItemClick(filteredItems[selectedIndex].path);
      }
    } else {
      const selectedItem = filteredItems.find(item => item.shortcut === e.key);
      if (selectedItem) {
        e.preventDefault();
        handleItemClick(selectedItem.path);
      }
    }
  };

  useEffect(() => {
    if (listRef.current && selectedIndex >= 0) {
      const selectedElement = listRef.current.children[selectedIndex];
      if (selectedElement) {
        selectedElement.scrollIntoView({ block: 'nearest' });
      }
    }
  }, [selectedIndex]);

  if (!isOpen) return null;

  const actionItems = filteredItems.filter(item => item.type === 'action');
  const pageItems = filteredItems.filter(item => item.type === 'page');

  return (
      <div className="fixed inset-0 z-60 flex items-start justify-center pt-16" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div className="relative w-full max-w-2xl bg-neutral-50/50 backdrop-blur-2xl rounded-lg shadow-2xl overflow-hidden border border-gray-200">
          <div className="p-4 flex items-center">
            <input
              ref={searchRef}
              type="text"
              className="w-full text-lg border-none outline-none bg-transparent text-slate-800 placeholder-slate-400 focus:ring-0"
              placeholder="Type a command..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            <span className="ml-2 mr-6 px-1.5 py-0.5 bg-gray-200 text-gray-600 rounded text-xs font-mono">
              Esc
            </span>
          </div>
          <div ref={listRef} className="max-h-96 overflow-y-auto pb-4">
            {actionItems.length > 0 && (
              <>
                <h3 className="px-7 py-2 text-xs font-semibold text-gray-500 uppercase">Actions</h3>
                <ul>
                  {actionItems.map((item, index) => (
                    <ListItem key={`action-${item.path}`} item={item} index={index} selectedIndex={selectedIndex} handleItemClick={handleItemClick} />
                  ))}
                </ul>
              </>
            )}
            {pageItems.length > 0 && (
              <>
                <h3 className="px-7 py-2 text-xs font-semibold text-gray-500 uppercase mt-2">Pages</h3>
                <ul>
                  {pageItems.map((item, index) => (
                    <ListItem key={`page-${item.path}`} item={item} index={index + actionItems.length} selectedIndex={selectedIndex} handleItemClick={handleItemClick} />
                  ))}
                </ul>
              </>
            )}
          </div>
        </div>
      </div>
  );
};

const ListItem = React.memo(({ item, index, selectedIndex, handleItemClick }) => (
  <li
    className={`py-2 px-7 cursor-pointer text-sm flex items-center justify-between ${
      index === selectedIndex ? 'bg-neutral-200' : 'hover:bg-neutral-200'
    }`}
  >
    <div className="flex items-center flex-grow">
      <span className="mr-3 text-slate-700">{item.icon}</span>
      <button onClick={() => handleItemClick(item.path)} className="text-slate-700 hover:text-slate-900">
        {item.name}
      </button>
    </div>
    <span className="ml-2 px-1.5 py-0.5 bg-gray-200 text-gray-600 rounded text-xs font-mono">
      {item.shortcut}
    </span>
  </li>
));

export default SpotlightSearch;