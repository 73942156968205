'use client';

import React, { useState } from 'react';
import Script from 'next/script'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/navigation';
import { usePathname } from 'next/navigation';
import { SpeedInsights } from "@vercel/speed-insights/next"
import '../src/css/global.css'

import { UserProvider } from '@auth0/nextjs-auth0/client';
import LayoutContext from '../src/context/LayoutContext'
import SpotlightSearch from '../src/components/SpotlightSearch';

const Sidebar = dynamic(() => import('../src/partials/Sidebar'), { ssr: false })
const Header = dynamic(() => import('../src/partials/Header'), { ssr: false })

export default function RootLayout({ children }) {
  const pathname = usePathname();
  const showSidebarHeader = !(pathname == '/' || pathname.includes('onboarding'));

  // contexts
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [taskList, setTaskList] = useState([]);
  const [task, setTask] = useState('');
  const [projectId, setProjectId] = useState('');
  const [isSpotlightOpen, setIsSpotlightOpen] = useState(false);
  const router = useRouter();

  return (
    <html lang="en">
      <header>
        <title>HoneyHive</title>
        <meta name="description" content="AI Evaluation and Observability Platform" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta property="og:title" content="HoneyHive" />
        <meta property="og:description" content="AI Evaluation and Observability Platform" />
      </header>
      <body className="font-inter antialiased bg-neutral-100 text-slate-600">
      <UserProvider loginUrl='/api/auth/login'>
        <Script id="posthog-script" strategy="afterInteractive">
          {`
            !function(t,e){var o,n,p,r;e.__SV||(window.posthog=e,e._i=[],e.init=function(i,s,a){function g(t,e){var o=e.split(".");2==o.length&&(t=t[o[0]],e=o[1]),t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}}(p=t.createElement("script")).type="text/javascript",p.async=!0,p.src=s.api_host+"/static/array.js",(r=t.getElementsByTagName("script")[0]).parentNode.insertBefore(p,r);var u=e;for(void 0!==a?u=e[a]=[]:a="posthog",u.people=u.people||[],u.toString=function(t){var e="posthog";return"posthog"!==a&&(e+="."+a),t||(e+=" (stub)"),e},u.people.toString=function(){return u.toString(1)+".people (stub)"},o="capture identify alias people.set people.set_once set_config register register_once unregister opt_out_capturing has_opted_out_capturing opt_in_capturing reset isFeatureEnabled onFeatureFlags getFeatureFlag getFeatureFlagPayload reloadFeatureFlags group updateEarlyAccessFeatureEnrollment getEarlyAccessFeatures getActiveMatchingSurveys getSurveys onSessionId".split(" "),n=0;n<o.length;n++)g(u,o[n]);e._i.push([i,s,a])},e.__SV=1)}(document,window.posthog||[]);
            posthog.init('phc_JYb55IwHO7AsY86XJ46OAJhE1aUsUG65bbk0HpEJRe7',{api_host:'https://app.posthog.com'})
          `}
        </Script>
        <Script id="sidebar-script" strategy="afterInteractive">
          {`
            if (localStorage.getItem('sidebar-expanded') == 'true') {
              document.querySelector('body').classList.add('sidebar-expanded');
            } else {
              document.querySelector('body').classList.remove('sidebar-expanded');
            }
          `}
          </Script>
          <div id="root">
            <div className="flex h-screen overflow-hidden">
              <LayoutContext.Provider value={
                  { 
                    sidebarOpen,
                    setSidebarOpen,
                    breadcrumbs,
                    setBreadcrumbs,
                    task,
                    setTask,
                    projectId,
                    setProjectId,
                    taskList,
                    setTaskList,
                    isSpotlightOpen,
                    setIsSpotlightOpen
                  }
                }>
                { showSidebarHeader && <Sidebar /> }

                <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-white">
                  { showSidebarHeader && <Header /> }
                  <main className="h-screen">
                    {children}
                  </main>
                </div>
                <SpotlightSearch 
                  isOpen={isSpotlightOpen} 
                  setIsOpen={setIsSpotlightOpen} 
                  router={router}
                />
              </LayoutContext.Provider>
              <SpeedInsights />
            </div>
          </div>
        </UserProvider>
      </body>
    </html>
  )
}
