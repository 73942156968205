import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

export const FadeIn = ({ children, duration = 0.5, delay = 0, ease = 'easeInOut' }) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration, delay, ease }}
  >
    {children}
  </motion.div>
);

export const SlideIn = ({ children, duration = 0.5, delay = 0, ease = 'easeInOut', direction = 'left', distance = 'medium' }) => {
  const getDistance = (size) => {
    switch (size) {
      case 'tiny': return 5;
      case 'small': return 20;
      case 'medium': return 50;
      case 'large': return 100;
      default: return 50;
    }
  };

  const slideDistance = getDistance(distance);

  const variants = {
    hidden: { 
      x: direction === 'left' ? -slideDistance : direction === 'right' ? slideDistance : 0,
      y: direction === 'up' ? slideDistance : direction === 'down' ? -slideDistance : 0,
      opacity: 0 
    },
    visible: { 
      x: 0, 
      y: 0, 
      opacity: 1 
    }
  };

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      exit="hidden"
      variants={variants}
      transition={{ duration, delay, ease }}
    >
      {children}
    </motion.div>
  );
};

export const ScaleIn = ({ children, duration = 0.5, delay = 0, ease = 'easeInOut' }) => (
  <motion.div
    initial={{ scale: 0.8, opacity: 0 }}
    animate={{ scale: 1, opacity: 1 }}
    exit={{ scale: 0.8, opacity: 0 }}
    transition={{ duration, delay, ease }}
  >
    {children}
  </motion.div>
);

/**
 * Transition Component
 * 
 * Usage (new):
 * <Transition
 *   show={isVisible}
 *   enter={{ type: 'fade', duration: 0.5, delay: 0, ease: 'easeInOut' }}
 *   exit={{ type: 'fade', duration: 0.3, delay: 0, ease: 'easeInOut' }}
 * >
 *   <YourComponent />
 * </Transition>
 * 
 * Usage (old):
 * <Transition type="fade" duration={0.5} delay={0} ease="easeInOut">
 *   <YourComponent />
 * </Transition>
 * 
 * Props:
 * - show: boolean (controls visibility, optional for backwards compatibility)
 * - type: string (for backwards compatibility)
 * - enter: object (transition properties for entering)
 * - exit: object (transition properties for exiting)
 * - duration, delay, ease: (for backwards compatibility)
 * 
 * Transition object properties:
 * - type: 'fade' | 'slide' | 'scale'
 * - duration: number (in seconds)
 * - delay: number (in seconds)
 * - ease: string
 * - direction: 'left' | 'right' | 'up' | 'down' (only for 'slide' type)
 * - distance: 'tiny' | 'small' | 'medium' | 'large' (only for 'slide' type)
 */
export const Transition = ({ children, show, type, enter, exit, duration, delay, ease, ...props }) => {
  const isOldUsage = type && !enter && !exit;
  const transitionProps = isOldUsage 
    ? { type, duration, delay, ease, ...props }
    : { enter: { type: 'fade', duration: 0.3, delay: 0, ease: 'easeInOut', ...enter }, 
        exit: { type: 'fade', duration: 0.2, delay: 0, ease: 'easeInOut', ...exit } };

  const getTransitionComponent = (props) => {
    switch (props.type) {
      case 'slide':
        return <SlideIn {...props}>{children}</SlideIn>;
      case 'scale':
        return <ScaleIn {...props}>{children}</ScaleIn>;
      default:
        return <FadeIn {...props}>{children}</FadeIn>;
    }
  };

  if (isOldUsage) {
    return getTransitionComponent(transitionProps);
  }

  return (
    <AnimatePresence mode="wait">
      {(show === undefined || show) && (
        <motion.div
          key="content"
          initial={transitionProps.enter.type === 'fade' ? { opacity: 0 } : undefined}
          animate={transitionProps.enter.type === 'fade' ? { opacity: 1 } : undefined}
          exit={transitionProps.exit.type === 'fade' ? { opacity: 0 } : undefined}
          transition={{ 
            duration: transitionProps.exit.duration, 
            delay: transitionProps.exit.delay, 
            ease: transitionProps.exit.ease 
          }}
        >
          {getTransitionComponent(transitionProps.enter)}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

/**
 * PageTransition Component
 * 
 * Usage:
 * <PageTransition>
 *   <YourPageComponent />
 * </PageTransition>
 * 
 * This component should wrap the main content of your page.
 * It provides a fade transition effect when navigating between pages.
 */
export const PageTransition = ({ children }) => (
  <AnimatePresence mode="wait">
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      {children}
    </motion.div>
  </AnimatePresence>
);

// Example usage in a component:
/*
import { Transition, PageTransition } from '../utils/Transitions';

function YourComponent() {
  return (
    <PageTransition>
      <main>
        <Transition type="fade" duration={0.5}>
          <h1>Welcome to the page</h1>
        </Transition>
        <Transition type="slide" direction="up" distance="medium" duration={0.7}>
          <p>This content will slide up</p>
        </Transition>
      </main>
    </PageTransition>
  );
}
*/